import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { useUserService } from '../services/userDataService';
import { useUserStore } from '../stores/userStore';
import { envs } from '../env';
import { routes as empireRoutes } from '../views/empire/routes';
import { routes as liberidisognareRoutes } from '../views/liberidisognare/routes';
import { routes as dbmRoutes } from '../views/dbm/routes';
import { routes as rocknrollRoutes } from '../views/rocknroll/routes';
import { routes as iconcommunityRoutes } from '../views/iconcommunity/routes';
import { routes as latinasempoderadasRoutes } from '../views/latinasempoderadas/routes';
import { routes as designbundles } from '../views/designbundles/routes';
import { routes as ketoneaffiliate } from '../views/ketoneaffiliate/routes';
import { routes as workflow } from '../views/workflow/routes';
import { routes as bombshell } from '../views/bombshell/routes';
import { routes as makelifewowteamRoutes } from '../views/makelifewowteam/routes';
import { routes as legendaryEmpireRoutes } from '../views/legendaryempire/routes';
import { routes as goldenglobalteamRoutes } from '../views/goldenglobalteam/routes';
import { routes as diamondclubRoutes } from '../views/diamondclub/routes';
import { routes as farmasiteamRoutes } from '../views/farmasiteam/routes';
import { TenantDTO, useTenantService } from '@/services/tenantService';
import { useRouterHelpers } from './useRouterHelpers';
import { routes as sharedRoutes } from '../views/shared/routes';

export const availableRoutesArray = [
  'MainLayout',
  'LandingPage',
  'DashboardHome',
  'ProfilePage',
  'ViewAllLeads',
  'LeadsPage',
  'ManageSubscription',
  'SagaMessagingPage',
  'ImportContacts',
  'HostLogin',
  'SupportCenter',
  'ForgotPassword',
  'RegisterPage',
  'HelpCenter',
  'ChatBotRedirect',
  'LibraryPage',
  'LibraryCategoryContentPage',
  'PageNotFound',
  'SagaLinkPage',
  'FastMessagingPage',
  'MassMessagingPage',
  'LinkMateLeadPage',
  'FollowUpPage',
  'LinkMateLayout',
  'LinkMateOnboarding',
  'LinkMatePage',
  'OnBoardingPage',
  'DMOPage',
  'ProductInformationFlow',
  'ProductInfoPageQuestionaire',
  'BusinessInfoPageQuestionaire',
  'BusinessInformationFlow',
  'KetoinfoSolutionPage',
  'OpportunitySolutionPage',
  'UserflowHelp',
  'ProspectingToolsSettingsPage',
  'UnicornPage',
  'BroadcastPage',
  'EmailListsPage',
  'LeadMagnetPage',
  'InfoPageLinkPage',
  'LeadMagnetLinkPage',
  'WelcomePage',
  'StripeCheckoutPage',
  'ExternalVideoPage',
  'ProductInformationFlowCollagen',
  'ProductInformationFlowNutrition',
  'BusinessInformationFlowWarm',
  'BusinessInformationFlowCold',
  'LeaderBoardPage',
  'AnalyticsPage',
] as const;

export type AvailableRoutes = (typeof availableRoutesArray)[number];

export type RouteWithName = RouteRecordRaw & { name?: AvailableRoutes; children?: RouteWithName[] };
declare module 'vue-router' {
  interface RouteMeta {
    // must be declared by every route
    requiresAuth: boolean;
    isFullPage?: boolean;
    hasAdminPage?: boolean;
  }
}

const VITE_LINKMATE_PREFIX = envs.VITE_LINKMATE_PREFIX;

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...sharedRoutes,
    ...empireRoutes,
    ...liberidisognareRoutes,
    ...dbmRoutes,
    ...latinasempoderadasRoutes,
    ...rocknrollRoutes,
    ...iconcommunityRoutes,
    ...designbundles,
    ...ketoneaffiliate,
    ...workflow,
    ...bombshell,
    ...makelifewowteamRoutes,
    ...legendaryEmpireRoutes,
    ...goldenglobalteamRoutes,
    ...diamondclubRoutes,
    ...farmasiteamRoutes,
  ],
  scrollBehavior(to) {
    if (to.hash) {
      const section = document.querySelector(to.hash);
      section?.scrollIntoView({ behavior: 'smooth' });
    } else window.document.getElementById('main-route')?.scrollTo({ top: 0 });
  },
});

router.beforeEach(async (to, from, next) => {
  const tenantService = useTenantService();
  const { getRouteName, getNewTenantPath } = useRouterHelpers();

  // Redirect to linkmate if needed
  if (
    window.location.origin === `${VITE_LINKMATE_PREFIX}` &&
    !to.path.includes('/link') &&
    (to.name != getRouteName('PageNotFound') || from.name === undefined)
  ) {
    const redirectPath = '/link' + to.path;
    next(redirectPath);
    return;
  }

  const userStore = useUserStore();
  if (to.name === getRouteName('RegisterPage') && userStore.loggedInInfo?.jwt) {
    next({ name: getRouteName('DashboardHome') });
    return;
  }

  if (useUserService().isUpdateNeeded()) {
    await userStore.updateLoggedInUserInfo();
  }

  // Redirect to correct tenant if needed
  const tenant = await computeTenant(to, userStore.loggedInInfo?.user?.tenant);
  if (tenant && !tenantService.isCorrectTenant(tenant.tenant)) {
    tenantService.setTenant(tenant.tenant);
    const newTenantPath = getNewTenantPath(tenant.tenant, to.fullPath);
    next(newTenantPath);
    return;
  }

  // If public page proceed
  if (!to.meta.requiresAuth || to.name === getRouteName('HelpCenter')) {
    next();
    return;
  }

  // If user is not logged in redirect to login page
  if (!userStore.loggedInInfo?.jwt || !userStore.loggedInInfo?.user) {
    next({ name: getRouteName('HostLogin') });
    return;
  }

  // If user has not completed checkout once redirect to stripe checkout page
  if (
    !userStore.subscriptionsStatus.isUserSubscribed &&
    userStore.subscriptionsStatus.status === 'incomplete_expired' &&
    to.name !== getRouteName('StripeCheckoutPage')
  ) {
    next({ name: getRouteName('StripeCheckoutPage') });
    return;
  }

  if (!userStore.subscriptionsStatus.isUserSubscribed && to.name != getRouteName('ManageSubscription')) {
    next({ name: getRouteName('ManageSubscription') });
    return;
  }
  if (userStore.adminStatus.isAdminModeActive && !to.meta.hasAdminPage) {
    next({ name: getRouteName('OnBoardingPage') });
    return;
  }
  if (to.name === getRouteName('WelcomePage') && userStore.loggedInInfo.user.hasBeenWelcomed) {
    next({ name: getRouteName('DashboardHome') });
    return;
  }
  if (to.name === getRouteName('StripeCheckoutPage') && userStore.subscriptionsStatus.isUserSubscribed) {
    next({ name: getRouteName('WelcomePage') });
    return;
  }
  next();
});

router.onError(error => {
  const isChunkLoadFailedWithMessage =
    error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed');
  if (isChunkLoadFailedWithMessage) {
    const response = confirm('New updates are available, please reload the page');
    if (response) {
      window.location.reload();
    }
  }
  return;
});

const computeTenant = async (toRoute: RouteLocationNormalized, loggedInTenant?: TenantDTO) => {
  if (!toRoute.path.includes('/link') || !toRoute.params.linkName || typeof toRoute.params.linkName !== 'string') {
    return loggedInTenant;
  }
  const { getUserFromLinkMateNickName } = useUserService();
  const user = await getUserFromLinkMateNickName(toRoute.params.linkName);
  return user?.tenant;
};

export default router;
