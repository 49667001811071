import { RouteWithName } from '@/router';
import { Tenant } from '@/services/tenantService';
import { useUserStore } from '@/stores/userStore';
import { addPrefixToRoutes } from '@/router/useRouterHelpers';
import { LeadMembership } from '@/services/leadDataService';
import { membershipsToShow } from './filtersToShow';

const prefix: Tenant = 'liberidisognare';

const routesWithoutPrefix: RouteWithName[] = [
  {
    path: '/',
    name: 'MainLayout',
    component: () => import('./MainLayoutView.vue'),
    children: [
      {
        path: '',
        name: 'LandingPage',
        component: () => import('./LandingPageView.vue'),
        meta: {
          requiresAuth: false,
          isFullPage: true,
        },
      },
      {
        path: 'welcome',
        name: 'WelcomePage',
        component: () => import('./WelcomePageView.vue'),
        meta: {
          requiresAuth: true,
          isFullPage: true,
        },
      },
      {
        path: 'viewAllLeads/:membership*',
        name: 'ViewAllLeads',
        props: true,
        component: () => import('./ViewAllLeadsView.vue'),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (membershipsToShow.includes(to.params.membership[0] as LeadMembership)) {
            next();
            return;
          }
          next({ name: `${prefix}PageNotFound` });
        },
      },
      {
        path: 'dashboard',
        name: 'DashboardHome',
        component: () => import('./DashboardHomeView.vue'),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
        },
      },
      {
        path: 'profilePage',
        name: 'ProfilePage',
        component: () => import('@/pages/ProfilePage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'leadsPage',
        name: 'LeadsPage',
        component: () => import('./LeadsPageView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'leaderboard',
        name: 'LeaderBoardPage',
        component: () => import('@/pages/LeaderBoardPage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'manageSubscription/:checkoutSessionId?',
        name: 'ManageSubscription',
        component: () => import('@/pages/ManageSubscription.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'sagaMessaging',
        name: 'SagaMessagingPage',
        component: () => import('@/pages/SagaMessagingPage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'massMessaging',
        name: 'MassMessagingPage',
        component: () => import('./MassMessagingPageView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'fastMessaging',
        name: 'FastMessagingPage',
        component: () => import('./FastMessagingPageView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'linkMateStart',
        name: 'LinkMateOnboarding',
        component: () => import('@/pages/linkMate/LinkMateOnboarding.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'followUp',
        redirect: '/followUp/TODAY',
      },
      {
        path: 'followUp/:filterSchedule',
        name: 'FollowUpPage',
        component: () => import('./FollowUpPageView.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'importContacts',
        name: 'ImportContacts',
        component: () => import('./ImportContactsPageView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'helpCenter',
        name: 'HelpCenter',
        component: () => import('@/pages/HelpCenter.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'library',
        name: 'LibraryPage',
        component: () => import('@/pages/LibraryPage.vue'),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
        },
      },
      {
        path: 'library/category/:categoryId',
        name: 'LibraryCategoryContentPage',
        component: () => import('@/pages/LibraryCategoryContentPage.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
        },
      },
      {
        path: 'onboarding',
        name: 'OnBoardingPage',
        component: () => import('./OnBoardingPageView.vue'),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
        },
      },
      {
        path: 'linkMate',
        name: 'LinkMatePage',
        component: () => import('./LinkMatePageView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'prospectingSettings',
        name: 'ProspectingToolsSettingsPage',
        component: () => import('./ProspectingToolsSettingsPageView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'analytics',
        name: 'AnalyticsPage',
        component: () => import('./AnalyticsPageView.vue'),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
        },
      },
      {
        path: 'dmo',
        name: 'DMOPage',
        component: () => import('./DMOPageView.vue'),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
        },
      },
      {
        path: 'infoPage',
        name: 'InfoPageLinkPage',
        component: () => import('./InfoPageLinkPageView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'leadMagnets',
        name: 'LeadMagnetLinkPage',
        component: () => import('./LeadMagnetLinkPageView.vue'),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
        },
      },
      {
        path: 'broadcast',
        name: 'BroadcastPage',
        component: () => import('./BroadcastPageView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'email-lists/:currentEmailListPropId?',
        name: 'EmailListsPage',
        component: () => import('./EmailListsPageView.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'supportCenter',
        name: 'SupportCenter',
        component: () => import('@/pages/HelpCenter.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: 'login',
        name: 'HostLogin',
        component: () => import('@/pages/HostLogin.vue'),
        meta: { requiresAuth: false },
        beforeEnter: (to, from, next) => {
          const userStore = useUserStore();
          if (userStore.loggedInInfo?.jwt) {
            const nextPageName = to.name && to.name != `${prefix}HostLogin` ? to.name : `${prefix}DashboardHome`;
            next({ name: nextPageName });
          }
          next();
        },
      },
      {
        path: 'checkout/:checkoutSessionId?',
        name: 'StripeCheckoutPage',
        component: () => import('@/pages/StripeCheckoutPage.vue'),
        meta: { requiresAuth: false, isFullPage: true },
        props: true,
      },
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: () => import('@/pages/ForgotPassword.vue'),
        meta: { requiresAuth: false },
        props: route => ({ token: route.query.token }),
      },
      {
        path: 'register',
        name: 'RegisterPage',
        component: () => import('@/pages/RegisterPage.vue'),
        meta: { requiresAuth: false },
      },
      {
        path: ':pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import('@/pages/PageNotFound.vue'),
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: '/link/:linkName',
    name: 'LinkMateLayout',
    component: () => import('@/pages/linkMate/LinkMateLayout.vue'),
    meta: {
      requiresAuth: false,
      isFullPage: true,
    },
    children: [
      {
        path: ``,
        name: `LinkMateLeadPage`,
        component: () => import('@/pages/linkMate/LinkMateLeadPage.vue'),
      },
      {
        path: 'opportunity',
        name: 'BusinessInformationFlow',
        component: () => import('./UnicornPageViewerView.vue'),
        props: query => ({ ...query.params }),
      },
      {
        path: 'info',
        name: 'ProductInformationFlow',
        component: () => import('./UnicornPageViewerView.vue'),
        props: query => ({ ...query.params }),
      },
      {
        path: ':slug',
        name: 'LeadMagnetPage',
        component: () => import('@/pages/linkMate/LeadMagnetPage.vue'),
        props: query => ({ ...query.params }),
      },
      {
        path: 'video/:videoId',
        name: 'ExternalVideoPage',
        component: () => import('@/pages/ExternalVideoPage.vue'),
        props: query => ({ ...query.params }),
      },
    ],
  },
];

export const routes = addPrefixToRoutes(routesWithoutPrefix, prefix);
